.contact-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 10px;

}

.contact-container * {
    justify-items: center;
    align-items: center;
    margin: 10px;
}

.contact-cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contact-image {
    max-width: 600px;
    max-height: auto
}