.footer {
    background-color: #194376;
    color: white;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
}

.footer-menu {
    display: flex;
    flex-direction: column;
}

.footer-menu a {
    color: white;
}

.footer-menu a:hover {
    color: burlywood;
}