

.testimonial-container {
    margin: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: top;
    justify-content: center;
}

.testimonial {
    max-width: 400px;
    padding: 30px;
    margin: 10px;
    background-color: snow;
    font-size: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
}