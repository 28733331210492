.headerdiv {
    background-color:#194376;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-self: left;
    width: 100%;
    height: 120px;
    margin-left: 0px;
    margin-right: 0px;
    font-family: sans-serif;


}

.headerdiv a {
    color: white;
    font-size: 18px;
}

.big-screen-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
}

.big-screen-menu * {
    margin-left: 15px;
    margin-right: 10px;
}

.big-screen-menu a:hover {
    color: burlywood;
}

.shipshape {
    display: flex;
    flex-direction: row;
    margin-left: 40px;
    margin-top: 20px;
}

.name h1 {
    font-size: 35px;
    font-family: 'Architects Daughter', cursive, sans-serif;
    color: white;
    font-weight: 400;
}

.name h3 {
    font-size: 17px;
    font-family: 'Architects Daughter', cursive, sans-serif;
    color: white;
    font-weight: 300
}

.headerdiv * {
    align-self: left;
    justify-self: center;
}

.name * {
    margin-top: 0px;
    margin-bottom: 0px;
    align-self: center;
}

img.ui.small.left.floated.image {
    max-width: 100px;
    margin: 0px, 0px, 0px, 0px;
    image-rendering: crisp-edges;

}


