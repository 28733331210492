.carousel {
    width: 100vw;
    max-height: 60vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

img {
    object-fit: cover;
    width: 100vw;
    height: 60vh;

}

