.portfolio-display {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-family: 'Lato', sans-serif;
}

.portfolio-display * {
    padding: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
}

.portfolio-display a {
    color: #194376;
}

.portfolio-display a:hover {
    color: burlywood;
}

.portfolio-display img {
    border: 20px solid snow;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.portfolio-display img:hover {
    border: 5px snow solid;
}

