body {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: 'Roboto', sans-serif;
  color: #194376;
}

footer {
  background-color: #194376;
  color:white;
}
