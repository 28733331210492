.coming-soon {
    height: 100vh;
    width: 100vw;
    background-color: #194376;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;



}

.coming-soon * {
    color: white;
    font-family: 'Lato', sans-serif;
}

img.logo-coming-soon {
    height: auto;
    width: 200px;
    image-rendering: crisp-edges;

}


